<template>
  <div class="myaccount">
    <div class="title bg_fff Plf20 Ptp15 Pbm15 Mtp25 orange">我的钱包</div>
    <div class="fun Mtp20">
      <div class="balance bg_fff">
        <account-item>
          <!-- <div slot="num">1000.00</div> -->
          <div slot="num">
            {{ userInfo.balance == -1 ? 0 : userInfo.balance }}
            <i
              class="el-icon-refresh-right curPointer"
              @click="GetLoginUserInfoByToken()"
            ></i>
          </div>
          <div slot="text1">余额</div>
          <!-- <div slot="text2" @click="showUp()">提现</div> -->
          <img slot="img" src="../../../assets/supplier/yue.png" />
        </account-item>
      </div>
      <div class="deposit bg_fff">
        <account-item>
          <!-- <div slot="num">{{ totalWithdrawal }}</div> -->
          <div slot="text1">提现记录</div>
          <div slot="text2" class="F14 btn co_09f" @click="viewWithdrawalRecord()">查看</div>
          <img slot="img" src="../../../assets/supplier/tixian.png" />
        </account-item>
      </div>
      <div class="account bg_fff">
        <account-item>
          <div slot="num" class="F14">{{ userInfo.withdrawalPwd ? '*******' : '暂未设置交易密码' }}</div>
          <div slot="text1">交易密码</div>
          <div slot="text2" class="F14 btn co_09f" @click="changePwd()">{{ userInfo.withdrawalPwd ? '修改' : '前去设置' }}</div>
          <img slot="img" src="../../../assets/supplier/user.png" />
        </account-item>
      </div>
    </div>
    <div class="title bg_fff Plf20 Ptp15 Pbm15 Mtp20">现金提现</div>
    <div class="withdrawal bg_fff Mtp20 Mbm85 het200 Plf20 Ptp30">
      <div class="withdrawalAmount">
        <el-form :model="withdrawalForm" label-width="100px">
          <el-row :gutter="1">
            <el-col :span="7">
              <el-form-item label="提现金额">
                <el-input-number
                  v-model="withdrawalForm.money"
                  @change="handleChange"
                  :min="0"
                ></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item>
                <el-button class="wid100" type="primary" @click="moneyShowUp()"
                  >立即提现</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <vue-pay
      ref="vuePay"
      :dialogShow="flag"
      @closeDialog="payMoney"
      :money="withdrawalForm.money"
    ></vue-pay>
  </div>
</template>
<script>
import accountItem from "@/components/supplier/accountItem.vue";
import VuePay from "@/components/supplier/vuePay.vue";
// import { getUserDrawTotalMoney } from "@/api/user";
import { mapGetters } from "vuex";
export default {
  components: { accountItem, VuePay },
  name: "MyAccount",
  data() {
    return {
      flag: false,
      password: "",
      withdrawalForm: {
        money: 0,
      },
      totalWithdrawal: "",
    };
  },
  created() {
    // this.getTotalWithdrawal();
    this.GetLoginUserInfoByToken();
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  methods: {
    GetLoginUserInfoByToken() {
      this.$store.dispatch("GetLoginUserInfoByToken");
    },
    handleChange(val) {
      this.withdrawalForm.money = val;
    },
    changePwd() {
      this.$router.push("/supplier/supplierHome/depositPwdChange");
    },
    viewWithdrawalRecord() {
      this.$router.push("/supplier/supplierHome/withdrawalRecord");
    },
    //获取总的提现金额
    // getTotalWithdrawal() {
    //   getUserDrawTotalMoney({ userId: this.userInfo.id }).then((res) => {
    //     if (res.success) {
    //       console.log(res.data);
    //       this.totalWithdrawal = res.data;
    //     } else {
    //       this.$message.error(res.msg);
    //     }
    //   });
    // },
    //提现密码
    moneyShowUp() {
      //先判断密码是否为空
      console.log("提现密码" + this.userInfo.withdrawalPwd);
      if (!this.userInfo.withdrawalPwd) {
        //为空，跳转到修改密码页面
        this.$confirm("您未设置提现密码，是否前往去设置？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.$router.push("/supplier/supplierHome/depositPwdChange");
        });
      } else {
        //判断提现金额是否大于余额
        if (
          this.userInfo.balance < this.withdrawalForm.money ||
          this.withdrawalForm.money == 0
        ) {
          //提示余额不足
          this.$alert("您的提现余额不足！", "提示", {
            confirmButtonText: "确定",
            callback: () => {},
          });
        } else {
          this.flag = true;
        }
      }
    },
    //支付密码
    payMoney(val) {
      this.withdrawalForm.money = 0;
      this.GetLoginUserInfoByToken();
      console.log("密码是" + val);
      this.password = val;
      this.flag = false;
    },
  },
};
</script>
<style scoped lang="scss">
.myaccount {
  .fun {
    display: flex;
    justify-content: space-between;
  }
}
</style>
