<template>
  <div id="accountitem" class="clearfix">
    <div class="left">
      <div class="moneyNum F18">
        <slot name="num"></slot>
      </div>
      <div class="text Mtp20 F14">
        <slot name="text1"></slot>
        <slot name="text2"></slot>
      </div>
    </div>
    <div class="img">
      <slot name="img">
      </slot>
    </div>
  </div>
</template>
<script>
export default {
  
}
</script>
<style scoped lang="scss">
#accountitem {
  width: 340px;
  height: 160px;
  padding: 40px 60px 25px 60px;
  box-sizing: border-box;
  .left {
    float: left;
    .moneyNum {
      font-weight: 500;
    }
  }
  .text .btn {
    cursor: pointer;
  }
  .img {
    float: right;
  }
  
}
</style>