<template>
  <div class="pay_box">
    <div class="modelBox" v-if="dialogShow">
      <div class="payDialog">
        <div class="tanchuangneirong">
          <div class="Mtp80">
            <img src="../../assets/supplier/mimaVerify.png" />
          </div>
          <div class="Mtp15">个人资金账户密码验证</div>
          <div class="Mtp30">
            <div class="password Plf200 Prt200">
              <div v-for="(item, index) in inputList" :key="index">
                <input
                  ref="pwd"
                  type="password"
                  v-model="item.val"
                  class="border-input"
                  @keyup="nextFocus($event, index, item.val)"
                  @keydown="changeValue(index)"
                />
              </div>
            </div>
            <div class="footer Mtp70">
              <el-button type="primary" @click="cancel" v-show="!loading"
                >取消{{ title }}</el-button
              >
              <el-button type="primary" @click="confirm" :loading="loading">{{
                !loading ? "确定" + title : "提交申请中"
              }}</el-button>
            </div>
          </div>
          <div class="Pbm80">
            <!-- <a href="#" class="co_09f Mtp15 F12">忘记密码？</a> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { checkUserWithdrawalPwd, createUserDrawMoney } from "@/api/user";
import { mapGetters } from "vuex";
export default {
  props: {
    dialogShow: {
      type: Boolean,
      default: false,
    },
    withdrawalType: {
      type: String,
      default: "1",
    },
    money: {
      type: Number,
    },
    imgUrl: {
      type: String,
    },
    title: {
      type: String,
      default: "提现",
    },
  },
  data() {
    return {
      loading: false,
      inputList: [
        { val: "" },
        { val: "" },
        { val: "" },
        { val: "" },
        { val: "" },
        { val: "" },
      ],
      password: "",
    };
  },
  // created() {
  //   this.showUp();
  // },
  watch: {
    dialogShow() {
      //确保每次打开窗口聚焦到输入框
      if (this.dialogShow == false) {
        console.log(this.dialogShow);
        this.password = "";
        let i = 0;
        let length = this.inputList.length;
        for (i = 0; i < length; i++) {
          this.inputList[i].val = "";
        }
        console.log("mima" + this.password);
      }
    },
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },

  methods: {
    /*对焦到下一个input框去*/
    nextFocus(el, index, val) {
      var dom = document.getElementsByClassName("border-input"),
        currInput = dom[index],
        nextInput = dom[index + 1],
        lastInput = dom[index - 1];
      console.log(currInput.value);
      /*这里的keyCode 根据不同的平台或许不同,安卓就是不是8*/

      if (el.keyCode != 8) {
        if (!/[0-9]/.test(val)) {
          this.inputList[index].val = "";
          console.log("请输入数字");
        } else {
          if (index < this.inputList.length - 1 && nextInput.value == "") {
            nextInput.focus();
          } else {
            currInput.focus();
          }
        }
      } else {
        if (index !== 0) {
          if (index !== 5 && nextInput.value !== "" && lastInput.value !== "") {
            currInput.focus();
            this.inputList[index].val = "";
          } else {
            lastInput.focus();
          }
        }
      }
      this.password =
        this.inputList[0].val +
        this.inputList[1].val +
        this.inputList[2].val +
        this.inputList[3].val +
        this.inputList[4].val +
        this.inputList[5].val;
      console.log(this.password);
    },
    /*当键盘按下的时候清空原有的数*/
    changeValue(index) {
      this.inputList[index].val = "";
    },

    cancel() {
      this.$confirm("将取消" + this.title + ", 是否确定?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$emit("closeDialog", false);
          this.$message({
            type: "info",
            message: "已取消" + this.title,
          });
        })
        .catch(() => {});
    },
    confirm() {
      // 校验提现密码是否正确
      this.loading = true;
      checkUserWithdrawalPwd(this.userInfo.id, this.password)
        .then((res) => {
          if (res.success) {
            if (this.title == "提现") {
              createUserDrawMoney(this.userInfo.id, this.money)
                .then((res) => {
                  this.loading = false;
                  if (res.success) {
                    this.$emit("closeDialog", this.password);
                    this.$message.success(this.title + "申请提交成功");
                  } else {
                    this.$message.error(res.msg);
                  }
                })
                .catch(() => {
                  this.loading = false;
                });
            } else {
              this.loading = false;
              this.$emit("closeDialog", this.password);
              this.$message.success(this.title + "申请提交成功");
            }
          } else {
            this.loading = false;
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          this.loading = false;
        });
      // }
    },
  },
};
</script>

<style scoped lang="scss">
.pay_box {
  position: relative;
  z-index: 2;
}
/* 支付对话框 */
.pay_box .modelBox {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.3);
}
.tanchuangneirong div {
  text-align: center;
  position: relative;
  a {
    position: absolute;
    right: 255px;
  }
}
.pay_box .payDialog {
  background-color: #fff;
  border: 1px solid #ccc;
  position: absolute;
  width: 760px;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}

.pay_box .payDialog .inputPass {
  position: absolute;
  border: 1px solid black;
  z-index: -999;
  right: 300px;
  top: 20px;
  border: transparent;
}
.pay_box .payDialog .inputPass:focus {
  outline: none;
}

.pay_box .payDialog form {
  width: 340px;
  display: flex;
  height: 40px;
  justify-content: space-around;
  background-color: #fff;
}

.pay_box .payDialog form > input {
  width: 40px;
  height: 40px;
  text-align: center;
}

.pay_box .password,
.pay_box .footer {
  display: flex;
  justify-content: center;
  .el-button {
    padding: 11px 33px;
  }
}
.border-input {
  background: #ffffff;
  width: 40px;
  font-size: 20px;
  height: 40px;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  border: 1px solid #333333;
}
.border-input:focus {
  border: 1px solid red;
}
</style>
